import { Service } from './../shared/services/service.service';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import moment from 'moment';
import { Role } from '../admin/users/roles/role';
import { Notify } from '../shared/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ModuleChangerComponent } from '../shared/component/module-changer/module-changer.component';
import { User } from '../admin/users/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends Service {
  // private api: string = environment.api;
  _user: WritableSignal<User> = signal<User>(new User());

  public menus: BehaviorSubject<[]> = new BehaviorSubject([]);
  public moduleChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private matDialog: MatDialog,
    private notify: Notify,
    private router: Router,
  ) {
    super(httpClient, 'cources');
  }
  signup_data(d: any) {
    this.httpClient
      .post(
        'https://api.hsforms.com/submissions/v3/integration/submit/23666306/db5fe98e-b3b2-4f7a-b3cf-b9adab4dfb8e',
        d
      )
      .subscribe(
        (response) => {
          console.log('Form data sent to HubSpot:', response);
        },
        (error) => {
          console.error('Error sending form data to HubSpot:', error);
        }
      );
  }
  settings(currentUrl: string = '') {
    this.getSettings().subscribe((res: any) => {

      if (currentUrl != '/my-account' && currentUrl.indexOf('auth/google/redirect') !== -1) {
        if (!res.data.hasMobileNumber) {
          this.notify.error("Please fill the mobile number to continue");
          this.router.navigateByUrl("/my-account");
        }
      }

      this.setSettings(res);
    });
  }
  setSettings(res: any = {}) {
    localStorage.setItem('user', JSON.stringify(res.data));
    // check if browser module is different than user module
    let { data } = res;
    if (data.module != localStorage.getItem('module')) {
      localStorage.setItem('module', data.module);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    this.updateUserSignal(new User(res.data));
    this.menus.next(res.data);
  }

  updateUserSignal(user: any) {
    this._user.update((currentUser: any) => {
      return { ...currentUser, ...user }; // Assuming User class accepts `res.data` in its constructor
    });
  }

  getMenus() {
    return this.httpClient.get(`${this.api}api/menus`).subscribe((res: any) => {
      this.menus.next(res.data);
    });
  }

  getSettings() {
    return this.httpClient.get(`${this.api}api/settings`);
  }

  resendEmail() {
    return this.httpClient.post(`${this.api}api/send`, {
      type: 'verification',
    });
  }

  login(email: string, password: string) {
    return this.httpClient.post(`${this.api}api/login`, { email, password });
  }

  signup(data: any) {
    return this.httpClient.post(`${this.api}api/signup`, data);
  }

  passwordResetLink(email: string) {
    return this.httpClient.post(`${this.api}api/password-reset-link`, {
      email,
    });
  }

  reset(value: any) {
    return this.httpClient.post(`${this.api}api/reset-password`, value);
  }

  socialLogin() {
    window.location.href = `${this.api}auth/google/login`;
  }

  public setSession(authResult: any) {
    const expiresAt = moment().add(authResult.expiresIn, 'second');
    localStorage.setItem('token', authResult.token);
    localStorage.setItem('user', JSON.stringify(authResult.user));
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  logout() {
    return this.httpClient.post(`${this.api}api/logout`, {}).subscribe(
      (res) => {
        this.removeAuthToken();

        window.location.href = `${this.api}logout`;
      },
      (err: HttpErrorResponse) => { }
    );
  }

  removeAuthToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('expires_at');
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  public get check() {
    return this.isLoggedIn();
  }

  public get user(): User {
    return new User(JSON.parse(<string>localStorage.getItem('user')));
  }

  public get isAdmin() {
    return this.check && this.user.role == Role.ADMIN;
  }

  public get isCordinator() {
    return this.check && this.user.role == Role.CORDINATOR;
  }

  public get isTeacher() {
    return this.check && this.user.role == Role.TEACHER;
  }

  public get isStudent() {
    return this.check && this.user.role == Role.STUDENT;
  }

  public get isVerified() {
    return this.check && this.user.emailVerifiedAt;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getAccessToken() {
    return localStorage.getItem('token');
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(<string>expiration);
    return moment(expiresAt);
  }

  loadModuleChanger() {
    let module = localStorage.getItem('module') ?? null;
    if (!module) {
      const dialog = this.matDialog.open(ModuleChangerComponent, {
        hasBackdrop: false,
        panelClass: ['mat-dialog-sm', 'blockscreen'],
        height: '100%',
        minWidth: '100vw',
        minHeight: '100vw',
      });
    }
  }

  changeModule(module: string) {
    return this.httpClient.post(`${this.api}api/change-module`, {}).subscribe({
      next: (res: any) => {
        this.notify.success(res.message);
        this.moduleChanged.next(true);
      },
    });
  }
}
