import { Status } from "src/app/shared/model/status";
import { environment } from "src/environments/environment";

export interface UserInterface {
  id: number;
  email: string;
  name: string;
  picture: string;
  mobileNumber: string;
  dialCode: string;
  city: string;
  country: string;
  role: string;
  status: string;
  emailVerifiedAt?: boolean;
  targetedScore?: number;
  package?: string;
  bands?: any;
  hasMobileNumber?: any;

  termCondition?: boolean;
  providerId?: string;
  socialProvider?: string;
  createdAt?: string;
  updatedAt?: string;
  isDeletable: boolean;
  isUpdatable: boolean;
}

export class User {
  id: number;
  email: string;
  name: string;
  picture: string;
  mobileNumber: string;
  dialCode: string;
  city: string;
  country: string;
  role: string;
  status: boolean;
  emailVerifiedAt?: boolean;
  targetedScore?: number;
  package?: string;
  bands: any;
  hasMobileNumber: boolean;

  createdAt: string | undefined;
  updatedAt: string | undefined;
  termCondition: boolean | undefined;
  providerId: string | undefined;
  socialProvider: string | undefined;
  isDeletable: boolean;
  isUpdatable: boolean;
  constructor(
    user: UserInterface = {
      id: 0,
      email: '',
      name: '',
      picture: '',
      mobileNumber: '',
      dialCode: '',
      city: '',
      country: '',
      role: '',
      hasMobileNumber: false,
      status: '',
      emailVerifiedAt: false,
      targetedScore: 0,
      bands: {},
      package: '',
      createdAt: '',
      updatedAt: '',
      termCondition: false,
      providerId: '',
      socialProvider: '',
      isDeletable: false,
      isUpdatable: false
    }
  ) {
    this.id = user.id;
    this.email = user.email;
    this.name = user.name;
    if (user.picture?.indexOf("google") != -1) {
      this.picture = user.picture;
    } else {
      this.picture = user.picture ? `${environment.api}${environment.assets}users/${user.picture}` : '/assets/img/user-placeholder.png';

    }

    this.hasMobileNumber = user.hasMobileNumber;
    this.mobileNumber = user.mobileNumber;
    this.dialCode = user.dialCode;
    this.city = user.city;
    this.country = user.country;
    this.role = user.role;
    this.status = user.status === Status.ACTIVE
    this.emailVerifiedAt = user?.emailVerifiedAt;
    this.targetedScore = user?.targetedScore;
    this.package = user?.package;
    this.bands = user?.bands;

    this.createdAt = user.createdAt;
    this.updatedAt = user.updatedAt;
    this.termCondition = user.termCondition;
    this.providerId = user.providerId;
    this.socialProvider = user.socialProvider;
    this.isDeletable = user.isDeletable;
    this.isUpdatable = user.isUpdatable;
  }

  isTeacher(): boolean {
    return this.role === 'TEACHER';
  }
}
